import { GetReportParameterListType } from "../../../../Types/report-types";
import { stylesFont } from "./styles";
import { Select, MenuItem } from "@material-ui/core";
import * as React from "react";

export interface SelectColumnNoProps {
  data: GetReportParameterListType;
  list: GetReportParameterListType[];
  noOfParameterColumns: number;
  handleChangeParameters: (parameters: GetReportParameterListType[]) => void;
}

const SelectColumnNo: React.FC<SelectColumnNoProps> = ({
  data,
  list,
  noOfParameterColumns,
  handleChangeParameters,
}) => {
  const customStyles = stylesFont();
  const [value, setValue] = React.useState<number>(data.columnNo);

  const handleChangeColumnNo = (
    event: React.ChangeEvent<{ value: unknown }>,
    parameterID: number
  ): void => {
    const columnNo: number = event.target.value as number;

    handleChangeParameters(
      list.map((p) => (p.parameterID === parameterID ? { ...p, columnNo } : p))
    );
  };

  React.useEffect(() => {
    setValue(data.columnNo);
  }, [data.columnNo]);

  return (
    <Select
      fullWidth
      value={value}
      disableUnderline={true}
      classes={{ root: customStyles.input }}
      onBlur={(event: React.ChangeEvent<{ value: unknown }>) =>
        handleChangeColumnNo(event, data.parameterID)
      }
      onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
        setValue(event.target.value as number)
      }
    >
      {Array.from(Array(noOfParameterColumns), (_, i) => i).map((item) => (
        <MenuItem
          classes={{ root: customStyles.input }}
          value={item + 1}
          key={item + 1}
        >
          {item + 1}
        </MenuItem>
      ))}
         <MenuItem
          classes={{ root: customStyles.input }}
          value={255}
          key={255}
        >
          {'Ext'}
        </MenuItem>
    </Select>
  );
};

export default SelectColumnNo;